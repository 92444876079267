<template>
  <div>
    <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
      <base-img position="left" :src="require('@/assets/orendaLogo.jpeg')" class="mr-3 hidden-xs-only" contain
        max-height="60" />

      <v-spacer />

      <div>
        <v-tabs class="hidden-sm-and-down" optional>
          <v-tab v-for="({ title, name }, i) in items" :key="i" :to="{ name }" :ripple="false"
            active-class="text--primary" class="font-weight-bold" min-width="96" text>{{ title }}</v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
import accountMixin from "@/mixins/accounts.js";

const anonymousItems = [
  { title: "Home", name: "Home" },
  { title: "About", name: "About" },
  { title: "Legal Guidelines", name: "Legal" },
  { title: "FAQ", name: "FAQ" },
  { title: "Contact", name: "Contact" },
];

const memberItems = [
  { title: "Dashboard", name: "Dashboard" },
  { title: "Profile", name: "Profile" },
  { title: "Card", name: "Card" },
  { title: "Loans", name: "Loans" },
];
const adminItems = [{ title: "Admin", name: "Admin" }];

const conditional_items = [{ title: "Application", name: "LoanApplication" }];

export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer"),
  },
  mixins: [accountMixin],
  data: () => ({
    drawer: null,
  }),
  computed: {
    anyCurrentLoan() {
      return this.$store.getters["loan/anyCurrentLoan"];
    },

    items() {
      console.log("is production", this.$orendaIsProduction);
      let allowedLinks = memberItems;
      if (!(this.$orendaIsProduction && this.anyCurrentLoan)) {
        allowedLinks = [...memberItems, ...conditional_items];
      }
      if (this.isAdmin) {
        return [...allowedLinks, ...adminItems];
      } else if (this.authorized) {
        return [...allowedLinks];
      } else
        return anonymousItems;
    },
  },
  watch: {
    showAdminItems: function (val) {
      if (!this.isAdminPath) {
        val && this.$router.push({ name: "Admin" });
      }
    },

    showMemberItems: function (val) {
      if (this.isAdminPath) {
        val && this.$router.push({ name: "Dashboard" });
      }
    }
  },
  methods: {
    isAdminPath() {
      const path = this.$route.path;
      return path.indexOf('/member/admin') == 0
    }
  }
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
